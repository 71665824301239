export const SEARCH_PERIOD_IN_DAY = 30
export const SEARCH_PERIOD_IN_MONTH = 3
export const SEARCH_PERIOD_IN_YEAR = 3

export const MAX_PROFILE = 3

export const SIX_ANIMALS = ["龙", "雀", "勾", "蛇", "虎", "玄"]

export const HS = ["甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸"]

export const EB = [
  "子",
  "丑",
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
  "申",
  "酉",
  "戌",
  "亥",
]

export const SIXTY_FOUR = {}

export const EBElement = {
  子: "water",
  丑: "earth",
  寅: "wood",
  卯: "wood",
  辰: "earth",
  巳: "fire",
  午: "fire",
  未: "earth",
  申: "metal",
  酉: "metal",
  戌: "earth",
  亥: "water",
}

export const HIDDEN_STEMS = {
  子: ["癸"],
  丑: ["癸己辛"],
  寅: ["丙戊甲"],
  卯: ["乙"],
  辰: ["卯戊癸"],
  巳: ["庚丙戊"],
  午: ["丁己"],
  未: ["卯丁己"],
  申: ["庚戊壬"],
  酉: ["辛"],
  戌: ["丁戊辛"],
  亥: ["壬甲"],
}

export const TRIGRAM_BINARY = [
  "011",
  "101",
  "000",
  "100",
  "110",
  "001",
  "010",
  "111",
]

export const DOOR = ["杜", "景", "死", "傷", "驚", "生", "休", "開"]

export const STAR = ["輔", "英", "芮", "沖", "柱", "任", "蓬", "心"]

export const GOD = ["蛇", "陰", "合", "符", "陳", "天", "地", "雀", "玄", "虎"]

export const SIXTY_PILLAR = [
  "甲子",
  "乙丑",
  "丙寅",
  "丁卯",
  "戊辰",
  "己巳",
  "庚午",
  "辛未",
  "壬申",
  "癸酉",
  "甲戌",
  "乙亥",
  "丙子",
  "丁丑",
  "戊寅",
  "己卯",
  "庚辰",
  "辛巳",
  "壬午",
  "癸未",
  "甲申",
  "乙酉",
  "丙戌",
  "丁亥",
  "戊子",
  "己丑",
  "庚寅",
  "辛卯",
  "壬辰",
  "癸巳",
  "甲午",
  "乙未",
  "丙申",
  "丁酉",
  "戊戌",
  "己亥",
  "庚子",
  "辛丑",
  "壬寅",
  "癸卯",
  "甲辰",
  "乙巳",
  "丙午",
  "丁未",
  "戊申",
  "己酉",
  "庚戌",
  "辛亥",
  "壬子",
  "癸丑",
  "甲寅",
  "乙卯",
  "丙辰",
  "丁巳",
  "戊午",
  "己未",
  "庚申",
  "辛酉",
  "壬戌",
  "癸亥",
]

export const BRANCH = [
  "子",
  "丑",
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
  "申",
  "酉",
  "戌",
  "亥",
]

export const COMPARE_DATE_FORMAT = "yyyyMMdd"

export const DEAULT_DATE_FORMAT = "yyyyMMdd"

export const DEAULT_DATETIME_FORMAT = "yyyyMMddTHH:mm:ssZ"

export const DEFAULT_DATETIME_FORMAT = "yyyyMMddTHH:mm"

export const DATETIME_FORMAT = "d MMM yyyy HH:mm"

export const DISPLAY_DATE_FORMAT = "d MMM yyyy"

export const DISPLAY_TIME_FORMAT = "HH:mm"

export const YEAR_MONTH_DATE_FORMAT = "yyyyMM"

export const PATTERN = {
  QIAN: 8,
  LI: 1,
  DUI: 5,
  GEN: 6,
  KUN: 2,
  ZHEN: 3,
  KAN: 7,
  CENTER: 4,
  XUN: 0,
}

export const FORWARD_LUO_SHU_PATTERN = [
  PATTERN.CENTER,
  PATTERN.QIAN,
  PATTERN.DUI,
  PATTERN.GEN,
  PATTERN.LI,
  PATTERN.KAN,
  PATTERN.KUN,
  PATTERN.ZHEN,
  PATTERN.XUN,
]

export const DIRECTION = ["SE", "S", "SW", "E", "", "W", "NE", "N", "NW"]

export const QM_SAN_YUAN = {
  冬至: [1, 7, 4],
  小寒: [2, 8, 5],
  大寒: [3, 9, 6],
  立春: [8, 5, 2],
  雨水: [9, 6, 3],
  惊蛰: [1, 7, 4],
  春分: [3, 9, 6],
  清明: [4, 1, 7],
  谷雨: [5, 2, 8],
  立夏: [4, 1, 7],
  小满: [5, 2, 8],
  芒种: [6, 3, 9],
  夏至: [9, 3, 6],
  小暑: [8, 2, 5],
  大暑: [7, 1, 4],
  立秋: [2, 5, 8],
  处暑: [1, 4, 7],
  白露: [9, 3, 6],
  秋分: [7, 1, 4],
  寒露: [6, 9, 3],
  霜降: [5, 8, 2],
  立冬: [6, 9, 3],
  小雪: [5, 8, 2],
  大雪: [4, 7, 1],
}

export const SOLAR_TERM = [
  "小寒",
  "大寒",
  "立春",
  "雨水",
  "惊蛰",
  "春分",
  "清明",
  "谷雨",
  "立夏",
  "小满",
  "芒种",
  "夏至",
  "小暑",
  "大暑",
  "立秋",
  "处暑",
  "白露",
  "秋分",
  "寒露",
  "霜降",
  "立冬",
  "小雪",
  "大雪",
  "冬至",
]

export const DIRECTION_PALACE = {
  n: PATTERN.KAN,
  ne: PATTERN.GEN,
  nw: PATTERN.QIAN,
  s: PATTERN.LI,
  se: PATTERN.XUN,
  sw: PATTERN.KUN,
  e: PATTERN.ZHEN,
  w: PATTERN.DUI,
}

export const getHSTwelveStage = (hs, eb) => {
  const stages = [
    "Growth",
    "Bath",
    "Youth",
    "Thrive",
    "Propsper",
    "Weaken",
    "Sick",
    "Death",
    "Grave",
    "Extinct",
    "Conceived",
    "Nourish",
  ]
  const lookup = {
    丁: [
      "酉",
      "申",
      "未",
      "午",
      "巳",
      "辰",
      "卯",
      "寅",
      "丑",
      "子",
      "亥",
      "戌",
    ],
    丙: [
      "寅",
      "卯",
      "辰",
      "巳",
      "午",
      "未",
      "申",
      "酉",
      "戌",
      "亥",
      "子",
      "丑",
    ],
    乙: [
      "午",
      "巳",
      "辰",
      "卯",
      "寅",
      "丑",
      "子",
      "亥",
      "戌",
      "酉",
      "申",
      "未",
    ],
    壬: [
      "申",
      "酉",
      "戌",
      "亥",
      "子",
      "丑",
      "寅",
      "卯",
      "辰",
      "巳",
      "午",
      "未",
    ],
    己: [
      "酉",
      "申",
      "未",
      "午",
      "巳",
      "辰",
      "卯",
      "寅",
      "丑",
      "子",
      "亥",
      "戌",
    ],
    庚: [
      "巳",
      "午",
      "未",
      "申",
      "酉",
      "戌",
      "亥",
      "子",
      "丑",
      "寅",
      "卯",
      "辰",
    ],
    戊: [
      "寅",
      "卯",
      "辰",
      "巳",
      "午",
      "未",
      "申",
      "酉",
      "戌",
      "亥",
      "子",
      "丑",
    ],
    甲: [
      "亥",
      "子",
      "丑",
      "寅",
      "卯",
      "辰",
      "巳",
      "午",
      "未",
      "申",
      "酉",
      "戌",
    ],
    癸: [
      "卯",
      "寅",
      "丑",
      "子",
      "亥",
      "戌",
      "酉",
      "申",
      "未",
      "午",
      "巳",
      "辰",
    ],
    辛: [
      "子",
      "亥",
      "戌",
      "酉",
      "申",
      "未",
      "午",
      "巳",
      "辰",
      "卯",
      "寅",
      "丑",
    ],
  }
  return stages[lookup[hs].findIndex(e => e === eb)]
}

export const TWENTY_FOUR_MOUNTAIN = [
  {
    sitting: "s1",
    facing: "n1",
    name: "壬",
    sittingName: "丙",
    start: "337.6",
  },
  {
    sitting: "s2",
    facing: "n2",
    name: "子",
    sittingName: "午",
    start: "352.6",
  },
  { sitting: "s3", facing: "n3", name: "癸", sittingName: "丁", start: "7.6" },
  {
    sitting: "sw1",
    facing: "ne1",
    name: "丑",
    sittingName: "未",
    start: "22.6",
  },
  {
    sitting: "sw2",
    facing: "ne2",
    name: "艮",
    sittingName: "坤",
    start: "37.6",
  },
  {
    sitting: "sw3",
    facing: "ne3",
    name: "寅",
    sittingName: "申",
    start: "52.6",
  },
  { sitting: "w1", facing: "e1", name: "甲", sittingName: "庚", start: "67.6" },
  { sitting: "w2", facing: "e2", name: "卯", sittingName: "酉", start: "82.6" },
  { sitting: "w3", facing: "e3", name: "乙", sittingName: "辛", start: "97.6" },
  {
    sitting: "nw1",
    facing: "se1",
    name: "辰",
    sittingName: "戌",
    start: "112.6",
  },
  {
    sitting: "nw2",
    facing: "se2",
    name: "巽",
    sittingName: "乾",
    start: "127.6",
  },
  {
    sitting: "nw3",
    facing: "se3",
    name: "巳",
    sittingName: "亥",
    start: "142.6",
  },
  {
    sitting: "n1",
    facing: "s1",
    name: "丙",
    sittingName: "壬",
    start: "157.6",
  },
  {
    sitting: "n2",
    facing: "s2",
    name: "午",
    sittingName: "子",
    start: "172.6",
  },
  {
    sitting: "n3",
    facing: "s3",
    name: "丁",
    sittingName: "癸",
    start: "187.6",
  },
  {
    sitting: "ne1",
    facing: "sw1",
    name: "未",
    sittingName: "丑",
    start: "202.6",
  },
  {
    sitting: "ne2",
    facing: "sw2",
    name: "坤",
    sittingName: "艮",
    start: "217.6",
  },
  {
    sitting: "ne3",
    facing: "sw3",
    name: "申",
    sittingName: "寅",
    start: "232.6",
  },
  {
    sitting: "e1",
    facing: "w1",
    name: "庚",
    sittingName: "甲",
    start: "247.6",
  },
  {
    sitting: "e2",
    facing: "w2",
    name: "酉",
    sittingName: "卯",
    start: "262.6",
  },
  {
    sitting: "e3",
    facing: "w3",
    name: "辛",
    sittingName: "乙",
    start: "277.6",
  },
  {
    sitting: "se1",
    facing: "nw1",
    name: "戌",
    sittingName: "辰",
    start: "292.6",
  },
  {
    sitting: "se2",
    facing: "nw2",
    name: "乾",
    sittingName: "巽",
    start: "307.6",
  },
  {
    sitting: "se3",
    facing: "nw3",
    name: "亥",
    sittingName: "巳",
    start: "322.6",
  },
]

export const EARLY_HEAVEN_TRIGRAM = {
  乾: "111",
  兌: "110",
  離: "101",
  震: "100",
  巽: "011",
  坎: "010",
  艮: "001",
  坤: "000",
}

export const TWELVE_OFFICER = [
  {
    chineseName: "建",
    englishName: "Establish",
    description:
      "it is suitable for getting on a new job, business travelling, business transactions or dealings",
  },
  {
    chineseName: "除",
    englishName: "Remove",
    description:
      "is usually used for cleansing activities. You also can interpret as the end of an relationship. Useful day for stock clearance sale.",
  },
  {
    chineseName: "滿",
    englishName: "Full",
    description: "It is good for signing agreements and official openings",
  },
  {
    chineseName: "平",
    englishName: "Balance",
    description:
      "This day is good for marriage, initiate constructiion works and commencing business negotiations.",
  },
  {
    chineseName: "定",
    englishName: "Stable",
    description:
      "This is a good day to start activities that have long term aspect to them and for activities that have lasting effect - like marriage. ",
  },
  {
    chineseName: "執",
    englishName: "Initiate",
    description:
      "This is the day that involve commencing of something like signing agreements and start a new project. ",
  },
  {
    chineseName: "破",
    englishName: "Destruction",
    description:
      "As its name suggests, use this day for activities that involve destroying something likes demolishing a buidling. ",
  },
  {
    chineseName: "危",
    englishName: "Danger",
    description:
      "All dangers day contain danger stars so to say avoid dangerous or high risk activities. ",
  },
  {
    chineseName: "成",
    englishName: "Success",
    description:
      "This is the most auspicious day of the 12 type of days. It is good for marriage, propose marriage, submit a business proposal , move into a new house and for burial. ",
  },
  {
    chineseName: "收",
    englishName: "Receive",
    description:
      "A receieve day is use to attain something likes a rewards. Use this day to start school, close a deal, ask for a raise, propose marriage and start a new job.",
  },
  {
    chineseName: "開",
    englishName: "Open",
    description:
      "This involve opening you premises to welcome your guests. Use for house warming, start a new job, and assume a new job. ",
  },
  {
    chineseName: "閉",
    englishName: "Close",
    description:
      "Qi is unmoving and at its lowest point. Avoid major activities.",
  },
]

export const TWENTY_EIGHT_CONSTELLATION = [
  "Horn",
  "Neck",
  "Foundation",
  "House",
  "Heart",
  "Tail",
  "Basket",
  "Dipper",
  "Ox",
  "Weaving Maiden",
  "Void",
  "Danger",
  "Room",
  "Wall",
  "Astride",
  "Mound",
  "Stomach",
  "Pleiades",
  "Net",
  "Beak",
  "Orion",
  "Well",
  "Ghost",
  "Willow",
  "Star",
  "Bow",
  "Wing",
  "Carriage",
]

export const ALAMAC_HOUR_STAR = {
  天官: {
    rating: 1,
    description: "Good for helpful people, nobleman",
  },
  金贵: {
    rating: 1,
    description: "Good for money finanical matters",
  },
  日马: {
    rating: 1,
    description: "Good for travelling",
  },
  福星: {
    rating: 1,
    description: "Good for money finanical matters",
  },
  日合: {
    rating: 1,
    description: "Good for communication, relationship",
  },
  玉堂: {
    rating: 1,
    description: "Good for asset acquisition",
  },
  日刑: {
    rating: -1,
    description: "bad for relationship, indicate harms and injury",
  },
  天牢: {
    rating: -1,
    description: "",
  },
  " 元武": {
    rating: -1,
    description: "thef, robbery, losses",
  },
  司命: {
    rating: -1,
    description: "",
  },
  日破: {
    rating: -1,
    description: "not used for important activies",
  },
  白虎: {
    rating: -1,
    description: "bodily harms and cuts, not idea for medical procedures",
  },
  日害: {
    rating: -1,
    description: "",
  },
  青龙: {
    rating: 1,
    description: "",
  },
  路空: {
    rating: 1,
    description: "",
  },
  天刑: {
    rating: -1,
    description: "",
  },
  旬空: {
    rating: -1,
    description: "",
  },
  朱雀: {
    rating: -1,
    description: "arguments and disputes",
  },
  宝光: {
    rating: 1,
    description: "",
  },
  勾陈: {
    rating: -1,
    description: "deceive and cheat",
  },

  日建: {
    rating: -1,
    description: "deceive and cheat",
  },
  喜神: {
    rating: -1,
    description: "deceive and cheat",
  },
  不遇: {
    rating: -1,
    description: "deceive and cheat",
  },
  名堂: {
    rating: -1,
    description: "deceive and cheat",
  },
}

export const ALAMAC_DAY_HOUR = {
  甲子: [
    ["日建", "金贵"],
    ["天乙", "日合"],
    ["喜神", "白虎"],
    ["日刑", "玉堂"],
    ["天牢"],
    [" 元武"],
    ["司命", "日破"],
    ["天乙", "日害"],
    ["青龙", "路空"],
    ["天官", "路空"],
    ["天刑", "旬空"],
    ["朱雀", "旬空"],
  ],
  乙丑: [
    ["天乙", "天刑"],
    ["日建", "朱雀"],
    ["金贵"],
    ["日禄", "宝光"],
    ["白虎"],
    ["玉堂", "不遇"],
    ["天牢", "日害"],
    ["日破", "路空"],
    ["天乙", "天官"],
    ["勾陈"],
    ["旬空", "喜神"],
    ["福星"],
  ],
  丙寅: [
    ["天官", "福星"],
    ["名堂"],
    ["日建", "天刑"],
    ["朱雀"],
    ["金贵", "路空"],
    ["喜神", "路空"],
    ["白虎"],
    ["玉堂"],
    ["日破", "喜神"],
    ["天乙", " 元武"],
    ["福星", "旬空"],
    ["天乙", "旬空"],
  ],
  丁卯: [
    ["司命"],
    ["勾陈"],
    ["青龙", "路空"],
    ["路空", "日建"],
    ["日害", "日刑"],
    ["日马", "朱雀"],
    ["日禄", "喜神"],
    ["宝光"],
    ["白虎"],
    ["天乙", "日破"],
    ["旬空", "日合"],
    [],
  ],
  戊辰: [
    ["天牢", "路空"],
    ["路空", "天乙"],
    ["不遇", "司命"],
    ["天官", "日刑"],
    ["日建", "日刑"],
    ["日禄", "名堂"],
    ["天刑"],
    ["朱雀", "天乙"],
    ["金贵", "福星"],
    ["宝光", "日合"],
    ["日破", "路空"],
    ["路空", "玉堂"],
  ],
  己巳: [
    ["天乙", "白虎"],
    ["不遇", "玉堂"],
    ["天官", "天牢"],
    [" 元武"],
    ["司命"],
    ["勾陈", "日建"],
    ["日禄", "青龙"],
    ["名堂", "福星"],
    ["日合", "路空"],
    ["路空", "朱雀"],
    ["旬空", "金贵"],
    ["日马", "日破"],
  ],
  庚午: [
    ["金贵", "日破"],
    ["天乙", "日害"],
    ["白虎", "日马"],
    ["玉堂"],
    ["天牢"],
    [" 元武"],
    ["天官", "路空"],
    ["日合", "路空"],
    ["青龙", "日禄"],
    ["名堂"],
    ["喜神", "旬空"],
    ["旬空", "朱雀"],
  ],
  辛未: [
    ["日害", "天刑"],
    ["朱雀", "日破"],
    ["天乙", "金贵"],
    ["宝光"],
    ["路空", "白虎"],
    ["天官", "路空"],
    ["日合", "天牢"],
    ["日建", " 元武"],
    ["喜神", "司命"],
    ["不遇", "日禄"],
    ["青龙", "旬空"],
    ["旬空", "名堂"],
  ],
  壬申: [
    ["青龙"],
    ["天官", "名堂"],
    ["日马", "日破"],
    ["天乙", "路空"],
    ["福星", "金贵"],
    ["天乙", "日合"],
    ["白虎", "喜神"],
    ["天官", "玉堂"],
    ["日建", "不遇"],
    [" 元武"],
    ["司命", "旬空"],
    ["日马", "旬空"],
  ],
  癸酉: [
    ["日禄", "路空"],
    ["勾陈", "路空"],
    ["青龙"],
    ["日破", "福星"],
    ["日合", "天刑"],
    ["天乙", "朱雀"],
    ["金贵"],
    ["宝光", "不遇"],
    ["白虎"],
    ["日建", "日刑"],
    ["天官", "旬空"],
    ["日马", "旬空"],
  ],
  甲戌: [
    [],
    ["天乙", " 元武"],
    ["日禄", "喜神"],
    ["勾陈", "日合"],
    ["青龙", "日破"],
    ["名堂"],
    ["天刑", "不遇"],
    ["天乙", "日刑"],
    ["日马", "路空"],
    ["天官", "旬空"],
    ["青龙", "白虎"],
    ["天牢", "玉堂"],
  ],
  乙亥: [
    ["天乙", "白虎"],
    ["福星", "玉堂"],
    ["日合", "天牢"],
    ["日禄", "天牢"],
    ["司命"],
    ["日马", "日破"],
    ["青龙", "路空"],
    ["路空", "名堂"],
    ["天官", "旬空"],
    ["旬空", "朱雀"],
    ["喜神", "金贵"],
    ["日建", "日刑"],
  ],
  丙子: [
    ["天官", "福星"],
    ["日合", "宝光"],
    ["日马", "白虎"],
    ["日刑", "玉堂"],
    ["不遇", "路空"],
    ["日禄", "路空"],
    ["司命", "日破"],
    ["勾陈", "日害"],
    ["喜神", "旬空"],
    ["天乙", "旬空"],
    ["福星", "天刑"],
    ["天乙", "朱雀"],
  ],
  丁丑: [
    ["日合", "天刑"],
    ["朱雀", "日建"],
    ["金贵", "路空"],
    ["不遇", "宝光"],
    ["白虎"],
    ["玉堂"],
    ["日禄", "天牢"],
    [" 元武", "日破"],
    ["旬空", "司命"],
    ["旬空", "天乙"],
    ["青龙", "日刑"],
    ["天官", "天乙"],
  ],
  戊寅: [
    ["青龙", "路空"],
    ["天乙", "路空"],
    ["日建", "不遇"],
    [],
    ["金贵", "喜神"],
    ["宝光", "金贵"],
    ["白虎"],
    ["玉堂", "天乙"],
    ["福星", "日破"],
    ["福星", "日破"],
    [" 元武", "旬空"],
    ["日合", "路空"],
  ],
  己卯: [
    ["日刑", "天乙"],
    ["不遇", "勾陈"],
    ["天官", "喜神"],
    ["名堂", "日建"],
    ["天刑", "日害"],
    ["朱雀", "日马"],
    ["金贵", "日禄"],
    ["宝光", "福星"],
    ["天乙", "旬空"],
    ["旬空", "玉堂"],
    ["日合", "天牢"],
    [" 元武"],
  ],
  庚辰: [
    ["天牢", "不遇"],
    ["福星", " 元武"],
    ["司命", "日马"],
    ["勾陈", "日害"],
    ["日建", "日刑"],
    ["名堂"],
    ["天官", "路空"],
    ["天乙", "路空"],
    ["日禄", "旬空"],
    ["日合", "旬空"],
    ["喜神", "不遇"],
    ["玉堂"],
  ],
  辛巳: [
    ["白虎"],
    ["玉堂"],
    ["天乙", "天牢"],
    [" 元武"],
    ["路空", "司命"],
    ["天官", "路空"],
    ["天乙", "青龙"],
    ["名堂"],
    ["日合", "旬空"],
    ["日禄", "不遇"],
    ["金贵"],
    ["宝光", "日破"],
  ],
  壬午: [
    ["金贵", "日破"],
    ["天官", "日害"],
    ["白虎", "路空"],
    ["天乙", "路空"],
    ["天牢", "福星"],
    ["天乙", " 元武"],
    ["日刑", "喜神"],
    ["勾陈", "日合"],
    ["不遇", "日马"],
    ["旬空", "名堂"],
    ["天刑"],
    ["朱雀", "日禄"],
  ],
  甲申: [
    ["青龙"],
    ["天乙", "名堂"],
    ["日禄", "日破"],
    ["朱雀"],
    ["金贵"],
    ["日合", "宝光"],
    ["不遇", "白虎"],
    ["天乙", "旬空"],
    ["日建", "路空"],
    ["天官", "路空"],
    ["司命"],
    [],
  ],
  乙酉: [
    ["天乙", "司命"],
    ["福星", "勾陈"],
    ["青龙"],
    ["名堂", "日破"],
    ["日合", "天刑"],
    ["不遇", "朱雀"],
    ["金贵", "路空"],
    ["宝光", "路空"],
    ["天官", "白虎"],
    ["日建", "日刑"],
    ["喜神", "天牢"],
    ["福星", " 元武"],
  ],
  丙戌: [
    ["天官", "天牢"],
    [" 元武"],
    ["司命"],
    ["日合", "勾陈"],
    ["青龙", "路空"],
    ["日禄", "路空"],
    ["天刑", "旬空"],
    ["旬空", "朱雀"],
    ["喜神", "日马"],
    ["天乙", "日害"],
    ["日建", "白虎"],
    ["天乙", "玉堂"],
  ],
  丁亥: [
    ["白虎"],
    ["玉堂"],
    ["路空", "日合"],
    ["不遇", "路空"],
    ["司命"],
    ["日马", "日破"],
    ["喜神", "旬空"],
    ["旬空", "名堂"],
    ["日害", "天刑"],
    ["朱雀", "天乙"],
    ["金贵"],
    ["日刑", "天官"],
  ],
  戊子: [
    ["日建", "路空"],
    ["日合", "路空"],
    ["日马", "不遇"],
    ["天官", "日刑"],
    ["司命", "天牢"],
    ["日禄", " 元武"],
    ["司命", "旬空"],
    ["天乙", "日害"],
    ["福星", "青龙"],
    ["名堂"],
    ["天刑", "路空"],
    ["朱雀", "路空"],
  ],
  己丑: [
    ["天刑", "日合"],
    ["日建", "不遇"],
    ["天官", "喜神"],
    ["宝光"],
    ["白虎"],
    ["玉堂"],
    ["日禄", "天牢"],
    ["福星", "日破"],
    ["天乙", "路空"],
    ["勾陈", "路空"],
    ["日刑", "青龙"],
    ["不遇", "日马"],
  ],
  庚寅: [
    ["青龙", "不遇"],
    ["天乙", "名堂"],
    ["日建", "天刑"],
    ["朱雀"],
    ["金贵"],
    ["宝光", "日害"],
    ["天官", "路空"],
    ["玉堂", "路空"],
    ["天牢", "日马"],
    [" 元武"],
    ["不遇", "司命"],
    [],
  ],
  辛卯: [
    ["司命", "日刑"],
    ["勾陈"],
    ["天乙", "青龙"],
    ["日建", "名堂"],
    ["路空", "天刑"],
    ["天官", "路空"],
    ["金贵", "旬空"],
    ["旬空", "宝光"],
    ["青龙", "白虎"],
    ["不遇", "日建"],
    ["日合", "天牢"],
    [],
  ],
  壬辰: [
    ["天牢"],
    ["天官", " 元武"],
    ["日马", "路空"],
    ["天乙", "路空"],
    ["福星", "日刑"],
    ["天乙", "名堂"],
    ["喜神", "天刑"],
    ["天官", "旬空"],
    ["不遇", "金贵"],
    ["宝光", "日合"],
    ["白虎", "日破"],
    ["日禄", "玉堂"],
  ],
  癸巳: [
    ["日禄", "路空"],
    ["玉堂", "路空"],
    ["天牢", "日害"],
    ["天乙", " 元武"],
    ["天官", "司命"],
    ["日建", "勾陈"],
    ["青龙", "旬空"],
    ["名堂", "不遇"],
    ["日合", "天刑"],
    ["朱雀"],
    ["金贵", "路空"],
    ["日马", "日破"],
  ],
  甲午: [
    ["日破", "金贵"],
    ["天乙", "日害"],
    ["白虎", "日禄"],
    ["玉堂"],
    ["天牢", "旬空"],
    [" 元武", "旬空"],
    ["日建", "不遇"],
    ["日合", "勾陈"],
    ["日马", "路空"],
    ["天官", "路空"],
    ["天刑"],
    ["朱雀"],
  ],
  乙未: [
    ["天官", "日害"],
    ["福星", "日刑"],
    ["金贵"],
    ["日禄", "宝光"],
    ["白虎", "旬空"],
    ["不遇", "日马"],
    ["日合", "路空"],
    ["日建", "路空"],
    ["天官", "司命"],
    ["勾陈"],
    ["青龙", "喜神"],
    ["名堂", "福星"],
  ],
  丙申: [
    ["天官", "福星"],
    ["名堂"],
    ["日马", "天刑"],
    ["朱雀"],
    ["金贵", "不遇"],
    ["日合", "旬空"],
    ["白虎"],
    ["玉堂"],
    ["天牢", "日建"],
    ["天乙", " 元武"],
    ["福星", "司命"],
    ["天乙", "日害"],
  ],
  丁酉: [
    ["司命"],
    ["勾陈"],
    ["青龙", "路空"],
    ["名堂", "日破"],
    ["天刑", "日合"],
    ["旬空", "朱雀"],
    ["日禄", "喜神"],
    ["宝光"],
    ["白虎"],
    ["天乙", "日刑"],
    ["天牢", "日害"],
    ["天官", " 元武"],
  ],
  戊戌: [
    ["天牢", "路空"],
    ["路空", "天乙"],
    ["不遇", "司命"],
    ["日合", "勾陈"],
    ["日破", "喜神"],
    ["名堂", "旬空"],
    ["天刑"],
    ["日刑", "天乙"],
    ["福星", "日马"],
    ["日害", "宝光"],
    ["路空", "日建"],
    ["金贵", "路空"],
  ],
  己亥: [
    ["天乙", "白虎"],
    ["玉堂", "不遇"],
    ["日合", "天牢"],
    [" 元武"],
    ["司命", "旬空"],
    ["日破", "日马"],
    ["日禄", "青龙"],
    ["福星", "名堂"],
    ["天乙", "日害"],
    ["路空", "朱雀"],
    ["金贵"],
    ["日禄", "不遇"],
  ],
  辛丑: [
    ["日合", "天刑"],
    ["日建", "朱雀"],
    ["天乙", "金贵"],
    ["宝光"],
    ["路空", "白虎"],
    ["天官", "路空"],
    ["天牢", "天乙"],
    [" 元武", "日破"],
    ["司命", "喜神"],
    ["日禄", "司命"],
    ["日刑", "青龙"],
    ["名堂", "日马"],
  ],
  壬寅: [
    ["青龙"],
    ["天官", "名堂"],
    ["日建", "路空"],
    ["天乙", "路空"],
    ["旬空", "福星"],
    ["天乙", "日刑"],
    ["白虎", "喜神"],
    ["天官", "玉堂"],
    ["日马", "天牢"],
    [" 元武"],
    ["司命"],
    ["日马", "勾陈"],
  ],
  癸卯: [
    ["喜神", "路空"],
    ["路空", "勾陈"],
    ["青龙"],
    ["天乙", "福星"],
    ["日害", "喜神"],
    ["日马", "朱雀"],
    ["金贵"],
    ["宝光", "不遇"],
    ["白虎"],
    ["日破", "玉堂"],
    ["路空", "日合"],
    [" 元武", "路空"],
  ],
  甲辰: [
    ["天牢"],
    ["天乙", " 元武"],
    ["旬空", "日马"],
    [],
    ["日建", "日刑"],
    ["名堂"],
    ["天刑", "不遇"],
    ["朱雀", "天乙"],
    ["金贵", "路空"],
    ["日合", "路空"],
    ["白虎", "日破"],
    ["玉堂"],
  ],
  乙巳: [
    ["天乙", "白虎"],
    ["福星", "玉堂"],
    ["天牢", "日害"],
    ["日禄", "旬空"],
    ["司命"],
    ["日建", "不遇"],
    ["青龙", "路空"],
    ["日建", "路空"],
    ["日合", "日刑"],
    ["朱雀"],
    ["喜神", "金贵"],
    ["宝光", "日破"],
  ],
  丙午: [
    ["天官", "日破"],
    ["宝光", "日害"],
    ["白虎", "旬空"],
    ["旬空", "玉堂"],
    ["不遇", "路空"],
    ["日禄", "路空"],
    ["日建", "天刑"],
    ["勾陈", "日合"],
    ["日马", "喜神"],
    ["名堂"],
    ["福星", "天刑"],
    ["天乙", "朱雀"],
  ],
  丁未: [
    ["日害", "天刑"],
    ["朱雀", "日破"],
    ["金贵", "路空"],
    ["路空", "宝光"],
    ["白虎"],
    ["日马", "玉堂"],
    ["日合", "天牢"],
    ["日建", " 元武"],
    ["司命"],
    ["天乙", "勾陈"],
    ["青龙"],
    ["天乙", "名堂"],
  ],
  戊申: [
    ["路空", "青龙"],
    ["天乙", "路空"],
    ["日马", "日破"],
    ["天官", "朱雀"],
    ["喜神", "金贵"],
    ["日禄", "日合"],
    ["白虎"],
    ["玉堂", "天乙"],
    ["天牢", "日建"],
    [" 元武"],
    ["路空", "司命"],
    ["日害", "路空"],
  ],
  己酉: [
    ["天乙", "司命"],
    ["不遇", "勾陈"],
    ["天官", "旬空"],
    ["名堂", "旬空"],
    ["日合", "天刑"],
    ["朱雀"],
    ["日禄", "金贵"],
    ["福星", "宝光"],
    ["天乙", "路空"],
    ["路空", "日建"],
    ["日害", "天牢"],
    ["日马", " 元武"],
  ],
  庚戌: [
    ["天牢", "不遇"],
    ["天乙", " 元武"],
    ["旬空", "司命"],
    ["日合", "旬空"],
    ["日破", "青龙"],
    ["名堂"],
    ["天官", "路空"],
    ["天乙", "路空"],
    ["日禄", "日马"],
    ["宝光", "日害"],
    ["日建", "不遇"],
    ["玉堂"],
  ],
  辛亥: [
    ["白虎"],
    ["玉堂"],
    ["日合", "天牢"],
    [" 元武", "旬空"],
    ["司命", "路空"],
    ["天乙", "日破"],
    ["天乙", "青龙"],
    ["名堂"],
    ["喜神", "日害"],
    ["日禄", "不遇"],
    ["金贵"],
    ["日建", "日刑"],
  ],
  壬子: [
    ["金贵", "日建"],
    ["天官", "日合"],
    ["日马", "路空"],
    ["天乙", "路空"],
    ["福星", "天牢"],
    ["天乙", " 元武"],
    ["日破", "司命"],
    ["天官", "日害"],
    ["不遇", "青龙"],
    ["名堂"],
    ["天刑"],
    ["日禄", "朱雀"],
  ],
  癸丑: [
    ["日合", "路空"],
    ["日建", "路空"],
    ["旬空", "金贵"],
    ["天乙", "旬空"],
    ["天官", "白虎"],
    ["玉堂", "天乙"],
    ["天牢", "日害"],
    ["不遇", "日破"],
    ["司命"],
    ["勾陈"],
    ["天官", "路空"],
    [],
  ],
  甲寅: [
    ["旬空"],
    ["旬空"],
    ["日建", "天刑"],
    ["朱雀"],
    ["金贵"],
    ["宝光", "日害"],
    ["不遇", "白虎"],
    ["天乙", "玉堂"],
    ["日马", "路空"],
    ["天官", "路空"],
    ["司命"],
    ["勾陈", "日合"],
  ],
  乙卯: [
    ["日刑", "天乙"],
    ["福星", "旬空"],
    ["青龙"],
    ["日建", "名堂"],
    ["日害", "天刑"],
    ["日马", "不遇"],
    ["金贵", "路空"],
    ["宝光", "路空"],
    ["天乙", "白虎"],
    ["日破", "玉堂"],
    ["天牢", "日合"],
    [" 元武"],
  ],
  丙辰: [
    ["天官", "天牢"],
    ["旬空", " 元武"],
    ["日马", "司命"],
    ["勾陈", "日害"],
    ["日禄", "路空"],
    ["日禄", "路空"],
    ["天刑"],
    ["朱雀"],
    ["喜神", "金贵"],
    ["天官", "日合"],
    ["福星", "日破"],
    ["天乙", "玉堂"],
  ],
  丁巳: [
    ["旬空", "白虎"],
    ["旬空", "玉堂"],
    ["天牢", "路空"],
    ["不遇", "路空"],
    ["司命"],
    ["日建", "勾陈"],
    ["日禄", "喜神"],
    ["名堂"],
    ["日合", "天刑"],
    ["天乙", "朱雀"],
    ["金贵"],
    ["天官", "日破"],
  ],
  戊午: [
    ["金贵", "日破"],
    ["天乙", "路空"],
    ["白虎", "不遇"],
    ["天官", "玉堂"],
    ["天牢", "喜神"],
    ["日禄", " 元武"],
    ["日刑"],
    ["日合", "勾陈"],
    ["日马", "青龙"],
    ["名堂"],
    ["天刑", "路空"],
    ["路空", "朱雀"],
  ],
  己未: [
    ["天乙", "日害"],
    ["日马", "日破"],
    ["天官", "喜神"],
    ["宝光"],
    ["白虎"],
    ["日马", "玉堂"],
    ["日合", "天牢"],
    [" 元武", "日建"],
    ["司命", "路空"],
    ["勾陈", "路空"],
    ["青龙"],
    ["名堂", "不遇"],
  ],
  庚申: [
    ["青龙", "不遇"],
    ["天乙", "旬空"],
    ["天刑", "日马"],
    ["天乙", "朱雀"],
    ["金贵"],
    ["日合", "宝光"],
    ["天官", "白虎"],
    ["路空"],
    ["天牢", "日建"],
    [" 元武"],
    ["司命", "不遇"],
    ["日害", "勾陈"],
  ],
  辛酉: [
    ["司命", "旬空"],
    ["旬空", "勾陈"],
    ["天乙", "青龙"],
    ["名堂", "日破"],
    ["日合", "路空"],
    ["天官", "路空"],
    ["天乙", "金贵"],
    ["宝光"],
    ["白虎"],
    ["日建", "不遇"],
    ["天牢", "日害"],
    [" 元武"],
  ],
  壬戌: [
    ["天牢", "旬空"],
    ["天官", "旬空"],
    ["司命", "路空"],
    ["路空", "日合"],
    ["日破", "福星"],
    ["天乙", "名堂"],
    ["喜神", "天刑"],
    ["天官", "朱雀"],
    ["日马", "不遇"],
    ["宝光", "日害"],
    ["日建", "白虎"],
    ["日禄", "玉堂"],
  ],
  癸亥: [
    ["日禄", "路空"],
    ["玉堂", "路空"],
    ["天牢", "日合"],
    ["天乙", " 元武"],
    ["天官", "司命"],
    ["日马", "勾陈"],
    ["青龙"],
    ["名堂", "不遇"],
    ["天刑", "日害"],
    ["朱雀"],
    ["天官", "路空"],
    ["日建", "路空"],
  ],
}
